var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', [_c('Row', [_c('Form', {
    ref: "searchForm",
    attrs: {
      "inline": "",
      "label-width": 70
    },
    nativeOn: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleGo.apply(null, arguments);
      }
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "链接地址",
      "prop": "url"
    }
  }, [_c('Input', {
    staticStyle: {
      "width": "350px"
    },
    attrs: {
      "type": "text",
      "placeholder": "http://",
      "clearable": ""
    },
    model: {
      value: _vm.url,
      callback: function ($$v) {
        _vm.url = $$v;
      },
      expression: "url"
    }
  })], 1), _c('FormItem', {
    staticStyle: {
      "margin-left": "-50px"
    }
  }, [_c('Button', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "primary",
      "icon": "ios-send"
    },
    on: {
      "click": _vm.handleGo
    }
  }, [_vm._v("前往")]), _c('Button', {
    attrs: {
      "icon": "md-open"
    },
    on: {
      "click": _vm.handleOpen
    }
  }, [_vm._v("新窗口中打开")])], 1)], 1)], 1), _c('Divider', {
    staticStyle: {
      "margin-top": "-10px",
      "margin-bottom": "0px"
    }
  }), _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('iframe', {
    attrs: {
      "id": "iframe",
      "src": _vm.go,
      "frameborder": "0",
      "width": "100%",
      "height": _vm.height,
      "scrolling": "auto"
    }
  }), _vm.loading ? _c('Spin', {
    attrs: {
      "fix": "",
      "size": "large"
    }
  }) : _vm._e()], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };